<template>
    <div class="list-cate">
        <li v-html="highlightMatches(item.name)" @click.stop class="list-level-1" v-b-toggle:[`list-itemlv2-${item.id}`]>
        </li>
        <span class="categories_quantity" v-if="item.children.length > 0"> ({{ item.children.length }})</span>
        <i 
            v-b-toggle:[`list-itemlv2-${item.id}`]
            v-if="item.children.length > 0"
            class="fa fa-angle-down arrow-level-1">
        </i>
        <b-dropdown class="action-level-1" variant="secondary" size="sm" no-caret right lazy>
            <template slot="button-content">
            <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
            </template>
            <!-- ITEM'S DETAIL -->
            <b-dropdown-item
                v-if="internalCate"
                @click="editItem(item)"
            >
                <span style="color: #3f4254; font-size: 12px">
                    <i class="flaticon2-box-1 icon-size-1-rem"></i>
                    &nbsp; Chi tiết
                </span>
            </b-dropdown-item>
            <!-- EDIT ITEM -->
            <b-dropdown-item
                @click="editItem(item)"
            >
                <span style="color: #3f4254; font-size: 12px">
                    <i class="flaticon2-pen icon-size-1-rem"></i>
                    &nbsp; Chỉnh sửa
                </span>
            </b-dropdown-item>
            <!-- MERGE CATE -->
            <b-dropdown-item
                @click="popupMergeCate(item)"
                v-if="checkPermission('CATE_MERGE') && internalCate"
            >
            <span style="color: #3f4254; font-size: 12px">
                <i
                class="fas fa-layer-group text-warning icon-size-1-rem"
                ></i>
                &nbsp; Gộp danh mục
            </span>
            </b-dropdown-item>
            <!-- MOVE CATE -->
            <b-dropdown-item
                @click="showMergeTreeModal(item)"
                v-show="checkPermission('CATE_MOVE') && internalCate"
            >
            <span style="color: #3f4254; font-size: 12px">
                <i
                style="color: #8908fd"
                class="flaticon-arrows icon-size-1-rem"
                ></i>
                &nbsp; Di chuyển danh mục
            </span>
            </b-dropdown-item>
            <!-- ADD CHILD CATE -->
            <b-dropdown-item
                @click="addChildCate(item)"
                v-show="checkPermission('CATE_INSERT')"
            >
            <span style="color: #3f4254; font-size: 12px">
                <i
                style="color: #1bc5bc"
                class="fas fa-plus icon-size-1-rem"
                ></i>
                &nbsp; Thêm danh mục con
            </span>
            </b-dropdown-item>
            <!-- DELETE CATE -->
            <b-dropdown-item
                @click="showDeleteAlert(item)"
                v-show="checkPermission('CATE_DELETE')"
            >
            <span style="color: #3f4254; font-size: 12px">
                <i
                style="color: #d33"
                class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                ></i>
                &nbsp; Xóa
            </span>
            </b-dropdown-item>
        </b-dropdown>

        <b-collapse :ref="`collapseAllLv2-${item.id}`" v-if="item.children.length > 0" :id="`list-itemlv2-${item.id}`">
            <template v-for="cateLv2 in item.children">
                <ul @click.stop class="list-level-2" :key="cateLv2.id">
                    <li v-html="highlightMatches(cateLv2.name)" v-b-toggle:[`list-itemlv3-${cateLv2.id}`] class="li">
                    </li>
                    <span class="categories_quantity" v-if="cateLv2.children.length > 0"> ({{ cateLv2.children.length }})</span>
                    <i  
                        v-b-toggle:[`list-itemlv3-${cateLv2.id}`]
                        v-if="cateLv2.children.length > 0"
                        class="fa fa-angle-down arrow-level-2">
                    </i>
                    <b-dropdown class="action-level-2" variant="secondary" size="sm" no-caret right lazy>
                    <template slot="button-content">
                    <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                    </template>
                    <!-- ITEM'S DETAIL -->
                    <b-dropdown-item
                        v-if="internalCate"
                        @click="editItem(cateLv2)"
                    >
                        <span style="color: #3f4254; font-size: 12px">
                            <i class="flaticon2-box-1 icon-size-1-rem"></i>
                            &nbsp; Chi tiết
                        </span>
                    </b-dropdown-item>
                    <!-- EDIT ITEM -->
                    <b-dropdown-item
                        @click="editItem(cateLv2)"
                    >
                        <span style="color: #3f4254; font-size: 12px">
                            <i class="flaticon2-pen icon-size-1-rem"></i>
                            &nbsp; Chỉnh sửa
                        </span>
                    </b-dropdown-item>
                    <!-- MERGE CATE -->
                    <b-dropdown-item
                        @click="popupMergeCate(cateLv2)"
                        v-if="checkPermission('CATE_MERGE') && internalCate"
                    >
                    <span style="color: #3f4254; font-size: 12px">
                        <i
                        class="fas fa-layer-group text-warning icon-size-1-rem"
                        ></i>
                        &nbsp; Gộp danh mục
                    </span>
                    </b-dropdown-item>
                    <!-- MOVE CATE -->
                    <b-dropdown-item
                        @click="showMergeTreeModal(cateLv2)"
                        v-show="checkPermission('CATE_MOVE') && internalCate"
                    >
                    <span style="color: #3f4254; font-size: 12px">
                        <i
                        style="color: #8908fd"
                        class="flaticon-arrows icon-size-1-rem"
                        ></i>
                        &nbsp; Di chuyển danh mục
                    </span>
                    </b-dropdown-item>
                    <!-- ADD CHILD CATE -->
                    <b-dropdown-item
                        @click="addChildCate(cateLv2)"
                        v-show="checkPermission('CATE_INSERT')"
                    >
                    <span style="color: #3f4254; font-size: 12px">
                        <i
                        style="color: #1bc5bc"
                        class="fas fa-plus icon-size-1-rem"
                        ></i>
                        &nbsp; Thêm danh mục con
                    </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="showDeleteAlert(cateLv2)"
                        v-show="checkPermission('CATE_DELETE')"
                    >
                    <span style="color: #3f4254; font-size: 12px">
                        <i
                        style="color: #d33"
                        class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                        ></i>
                        &nbsp; Xóa
                    </span>
                    </b-dropdown-item>
                    </b-dropdown>
                </ul>
                <b-collapse :ref="`collapseAllLv3-${item.id}`" v-if="cateLv2.children.length > 0" :key="cateLv2.code" :id="`list-itemlv3-${cateLv2.id}`">
                    <template v-for="cateLv3 in cateLv2.children">
                        <ul @click.stop class="list-level-3" :key="cateLv3.id">
                            <li v-html="highlightMatches(cateLv3.name)" v-b-toggle:[`list-itemlv4-${cateLv3.id}`] class="li">
                            </li>
                            <span class="categories_quantity" v-if="cateLv3.children.length > 0"> ({{ cateLv3.children.length }})</span>
                            <i 
                                v-b-toggle:[`list-itemlv4-${cateLv3.id}`]
                                v-if="cateLv3.children.length > 0"
                                class="fa fa-angle-down arrow-level-3">
                            </i>
                            <b-dropdown class="action-level-3" variant="secondary" size="sm" no-caret right lazy>
                                <template slot="button-content">
                                <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                                </template>
                                <!-- ITEM'S DETAIL -->
                                <b-dropdown-item
                                    v-if="internalCate"
                                    @click="editItem(cateLv3)"
                                >
                                    <span style="color: #3f4254; font-size: 12px">
                                        <i class="flaticon2-box-1 icon-size-1-rem"></i>
                                        &nbsp; Chi tiết
                                    </span>
                                </b-dropdown-item>
                                <!-- EDIT ITEM -->
                                <b-dropdown-item
                                    @click="editItem(cateLv3)"
                                >
                                    <span style="color: #3f4254; font-size: 12px">
                                        <i class="flaticon2-pen icon-size-1-rem"></i>
                                        &nbsp; Chỉnh sửa
                                    </span>
                                </b-dropdown-item>
                                <!-- MERGE CATE -->
                                <b-dropdown-item
                                    @click="popupMergeCate(cateLv3)"
                                    v-if="checkPermission('CATE_MERGE') && internalCate"
                                >
                                <span style="color: #3f4254; font-size: 12px">
                                    <i
                                    class="fas fa-layer-group text-warning icon-size-1-rem"
                                    ></i>
                                    &nbsp; Gộp danh mục
                                </span>
                                </b-dropdown-item>
                                <!-- MOVE CATE -->
                                <b-dropdown-item
                                    @click="showMergeTreeModal(cateLv3)"
                                    v-show="checkPermission('CATE_MOVE') && internalCate"
                                >
                                <span style="color: #3f4254; font-size: 12px">
                                    <i
                                    style="color: #8908fd"
                                    class="flaticon-arrows icon-size-1-rem"
                                    ></i>
                                    &nbsp; Di chuyển danh mục
                                </span>
                                </b-dropdown-item>
                                <!-- ADD CHILD CATE -->
                                <b-dropdown-item
                                    @click="addChildCate(cateLv3)"
                                    v-show="checkPermission('CATE_INSERT')"
                                >
                                <span style="color: #3f4254; font-size: 12px">
                                    <i
                                    style="color: #1bc5bc"
                                    class="fas fa-plus icon-size-1-rem"
                                    ></i>
                                    &nbsp; Thêm danh mục con
                                </span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="showDeleteAlert(cateLv3)"
                                    v-show="checkPermission('CATE_DELETE')"
                                >
                                <span style="color: #3f4254; font-size: 12px">
                                    <i
                                    style="color: #d33"
                                    class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                                    ></i>
                                    &nbsp; Xóa
                                </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </ul>
                        <b-collapse :ref="`collapseAllLv4-${item.id}`" v-if="cateLv3.children.length > 0" :key="cateLv3.code" :id="`list-itemlv4-${cateLv3.id}`">
                            <template v-for="cateLv4 in cateLv3.children">
                                <ul @click.stop class="list-level-4" :key="cateLv4.id">
                                    <li v-html="highlightMatches(cateLv4.name)" v-b-toggle:[`list-itemlv5-${cateLv4.id}`] class="li">
                                    </li>
                                    <span class="categories_quantity" v-if="cateLv4.children.length > 0"> ({{ cateLv4.children.length }})</span>
                                    <i  
                                        v-b-toggle:[`list-itemlv5-${cateLv4.id}`]
                                        v-if="cateLv4.children.length > 0"
                                        class="fa fa-angle-down arrow-level-4">
                                    </i>
                                    <b-dropdown class="action-level-4" variant="secondary" size="sm" no-caret right lazy>
                                        <template slot="button-content">
                                        <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                                        </template>
                                        <!-- ITEM'S DETAIL -->
                                        <b-dropdown-item
                                            v-if="internalCate"
                                            @click="editItem(cateLv4)"
                                        >
                                            <span style="color: #3f4254; font-size: 12px">
                                                <i class="flaticon2-box-1 icon-size-1-rem"></i>
                                                &nbsp; Chi tiết
                                            </span>
                                        </b-dropdown-item>
                                        <!-- EDIT ITEM -->
                                        <b-dropdown-item
                                            @click="editItem(cateLv4)"
                                        >
                                            <span style="color: #3f4254; font-size: 12px">
                                                <i class="flaticon2-pen icon-size-1-rem"></i>
                                                &nbsp; Chỉnh sửa
                                            </span>
                                        </b-dropdown-item>
                                        <!-- MERGE CATE -->
                                        <b-dropdown-item
                                            @click="popupMergeCate(cateLv4)"
                                            v-if="checkPermission('CATE_MERGE') && internalCate"
                                        >
                                        <span style="color: #3f4254; font-size: 12px">
                                            <i
                                            class="fas fa-layer-group text-warning icon-size-1-rem"
                                            ></i>
                                            &nbsp; Gộp danh mục
                                        </span>
                                        </b-dropdown-item>
                                        <!-- MOVE CATE -->
                                        <b-dropdown-item
                                            @click="showMergeTreeModal(cateLv4)"
                                            v-show="checkPermission('CATE_MOVE') && internalCate"
                                        >
                                        <span style="color: #3f4254; font-size: 12px">
                                            <i
                                            style="color: #8908fd"
                                            class="flaticon-arrows icon-size-1-rem"
                                            ></i>
                                            &nbsp; Di chuyển danh mục
                                        </span>
                                        </b-dropdown-item>
                                        <!-- ADD CHILD CATE -->
                                        <b-dropdown-item
                                            @click="addChildCate(cateLv4)"
                                            v-show="checkPermission('CATE_INSERT')"
                                        >
                                        <span style="color: #3f4254; font-size: 12px">
                                            <i
                                            style="color: #1bc5bc"
                                            class="fas fa-plus icon-size-1-rem"
                                            ></i>
                                            &nbsp; Thêm danh mục con
                                        </span>
                                        </b-dropdown-item>
                                        <b-dropdown-item
                                            @click="showDeleteAlert(cateLv4)"
                                            v-show="checkPermission('CATE_DELETE')"
                                        >
                                        <span style="color: #3f4254; font-size: 12px">
                                            <i
                                            style="color: #d33"
                                            class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                                            ></i>
                                            &nbsp; Xóa
                                        </span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </ul>
                                <b-collapse :ref="`collapseAllLv5-${item.id}`" v-if="cateLv4.children.length > 0" :key="cateLv4.code" :id="`list-itemlv5-${cateLv4.id}`">
                                    <template v-for="cateLv5 in cateLv4.children">
                                        <ul class="list-level-5" :key="cateLv5.id">
                                            <li v-html="highlightMatches(cateLv5.name)" class="li">
                                            </li>
                                            <b-dropdown class="action-level-5" variant="secondary" size="sm" no-caret right lazy>
                                                <template slot="button-content">
                                                <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                                                </template>
                                                <!-- ITEM'S DETAIL -->
                                                <b-dropdown-item
                                                    v-if="internalCate"
                                                    @click="editItem(cateLv5)"
                                                >
                                                    <span style="color: #3f4254; font-size: 12px">
                                                        <i class="flaticon2-box-1 icon-size-1-rem"></i>
                                                        &nbsp; Chi tiết
                                                    </span>
                                                </b-dropdown-item>
                                                <!-- EDIT ITEM -->
                                                <b-dropdown-item
                                                    @click="editItem(cateLv5)"
                                                >
                                                    <span style="color: #3f4254; font-size: 12px">
                                                        <i class="flaticon2-pen icon-size-1-rem"></i>
                                                        &nbsp; Chỉnh sửa
                                                    </span>
                                                </b-dropdown-item>
                                                <!-- MERGE CATE -->
                                                <b-dropdown-item
                                                    @click="popupMergeCate(cateLv5)"
                                                    v-if="checkPermission('CATE_MERGE') && internalCate"
                                                >
                                                <span style="color: #3f4254; font-size: 12px">
                                                    <i
                                                    class="fas fa-layer-group text-warning icon-size-1-rem"
                                                    ></i>
                                                    &nbsp; Gộp danh mục
                                                </span>
                                                </b-dropdown-item>
                                                <!-- MOVE CATE -->
                                                <b-dropdown-item
                                                    @click="showMergeTreeModal(cateLv5)"
                                                    v-show="checkPermission('CATE_MOVE') && internalCate"
                                                >
                                                <span style="color: #3f4254; font-size: 12px">
                                                    <i
                                                    style="color: #8908fd"
                                                    class="flaticon-arrows icon-size-1-rem"
                                                    ></i>
                                                    &nbsp; Di chuyển danh mục
                                                </span>
                                                </b-dropdown-item>
                                            
                                                <b-dropdown-item
                                                    @click="showDeleteAlert(cateLv5)"
                                                    v-show="checkPermission('CATE_DELETE')"
                                                >
                                                <span style="color: #3f4254; font-size: 12px">
                                                    <i
                                                    style="color: #d33"
                                                    class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                                                    ></i>
                                                    &nbsp; Xóa
                                                </span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </ul>
                                    </template>
                                </b-collapse>
                            </template>
                        </b-collapse>
                    </template>
                </b-collapse>
            </template>
        </b-collapse>
    </div>
</template>

<script>
import { checkPermission } from '@/utils/saveDataToLocal';
export default {
    props: { 
        item : { 
            type: Object,
        },
        internalCate:{ 
            type: Boolean,
        },
        searchName: {
            type: String,
            default: '',
        },
        index: { 
            type: Number,
        }
    },
    methods: { 
        checkPermission,
        editItem(item){
            this.$emit('editItem',item);
        },
        popupMergeCate(item){ 
            this.$emit('popupMergeCate',item)
        },
        showMergeTreeModal(item){
            this.$emit('showMergeTreeModal',item)
        },
        addChildCate(item){
            this.$emit('addChildCate',item)
        },
        showDeleteAlert(item){ 
            this.$emit('showDeleteAlert',item)
        },
        checkViewOnly: function () {
            let count = 0;
            if (checkPermission('CATE_VIEW')) {
                count++;
            }
            if (checkPermission('CATE_UPDATE')) {
                count++;
            }
            if (count === 1) {
                return true;
            } else {
                return false;
            }
        },
        highlightMatches(text = '') {
            const searchTerm = this.searchName ? this.searchName.trim() : '';
    
            const matchExists = text.toLowerCase().includes(searchTerm.toLowerCase());

            if (!matchExists) return text;

            const regex = new RegExp(searchTerm, 'ig');
            return text.replace(
                regex,
                (matchedText) => `<strong style="color: #FF2400">${matchedText}</strong>`
            );
        },
    },
}
</script>

<style lang="css" scoped>

.btn.btn-secondary{ 
    background-color: transparent !important;
    border-color: transparent !important;
}
.list-cate{ 
    position:relative;
}

.list-level-1{
  list-style-type: "1. ";
  margin-left: 10px;
  line-height: 40px;
  margin-top: 4px;
}

.list-level-2{ 
    position: relative;
}

.list-level-2 .li { 
    list-style-type: "2. ";
    line-height: 30px;
    margin-left: 40px ;
}

.list-level-3{ 
    position: relative;
}

.list-level-3 .li{ 
    list-style-type: "3. ";
    margin-left: 55px;
    line-height: 30px;
}

.list-level-4{ 
    position: relative;
}

.list-level-4 .li{ 
    list-style-type: "4. ";
    margin-left: 70px;
    line-height: 30px;
}

.list-level-5{ 
    position: relative;
}

.list-level-5 .li { 
    list-style-type: "5. ";
    margin-left: 85px;
    line-height: 30px;
}

.arrow-level-1{ 
    position: absolute;
    top: 12px;
    right: 70px;
    color: #464e5f;
}

.categories_quantity{ 
    position: absolute;
    top: 10px;
    right: 85px;
}

.arrow-level-2{ 
    position: absolute;
    top: 12px;
    right: 70px;
    color: #464e5f;
}

.arrow-level-3{ 
    position: absolute;
    top: 12px;
    right: 70px;
    color: #464e5f;
}

.arrow-level-4{ 
    position: absolute;
    top: 12px;
    right: 70px;
    color: #464e5f;
}

.action-level-1{ 
  position: absolute;
  top: 2px;
  right: 17px;
}

.action-level-2{ 
  position: absolute;
  top: 2px;
  right: 17px;
}

.action-level-3{ 
  position: absolute;
  top: 2px;
  right: 17px;
}

.action-level-4{ 
  position: absolute;
  top: 2px;
  right: 17px;
}

.action-level-5{ 
  position: absolute;
  top: 2px;
  right: 17px;
}
.collapsing {
  transition: none !important;
}
</style>